import React from 'react';
import Layout from '../components/layout/Layout';
import freefallFeather from '../images/free-fall-feathers.png';
import technologyChip from '../images/technology-chip.jpeg';
import { Section } from '../components/Section';
import { Card, CardImage } from '../components/Card';
import { Helmet } from 'react-helmet';

const differentiators = [
  {
    id: 1,
    title: 'Unmatched Business Value.',
    description:
      "We don't just offer solutions; we provide value. Harnessing the power of innovative technologies, we craft intelligent strategies that bolster business growth. Every solution we devise is uniquely tailored, promoting data-centric decision-making for our clients."
  },
  {
    id: 2,
    title: 'Technological Prowess.',
    description:
      "Technology isn't just what we do; it's who we are. Our deep-rooted expertise spans DevOps, full-stack software engineering, cloud solutions, AI/ML, and cybersecurity. From security automation and FISMA compliance to intelligent analysis, we merge these domains to deliver holistic solutions addressing diverse needs."
  },
  {
    id: 3,
    title: 'Our Remarkable Team.',
    description:
      "Our achievements are driven by our people. We're meticulous in our selection process, emphasizing technical prowess and character. With a rigorous vetting system involving coding tests, personality assessments, and security screenings, we ensure our team comprises the brightest, most trustworthy professionals. Encouraging a culture of agility, innovation, and collaboration, we empower our teams to exceed client expectations consistently."
  }
];

const AboutUs = () => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather - About Us</title>
    </Helmet>
    <Section bgImage={freefallFeather}>
      <div className='container mx-auto relative isolate overflow-hidden px-6 lg:overflow-visible lg:px-0'>
        <div className='my-8 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10'>
          <div className='lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8'>
            <div className='lg:pr-4'>
              <div className='lg:max-w-lg'>
                <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                  Who We Are
                </h1>
                <p className='mt-6 text-xl leading-8 text-gray-700'>
                  LightFeather is a{' '}
                  <span className='text-blue-lighter'>leading digital technology company</span> that
                  is recognized for its innovation and excellence. Our team is comprised of top-tier
                  talent and cutting-edge technology, providing our clients and partners with
                  unparalleled thought leadership, technical capability, and operational excellence.
                  We pride ourselves on empowering our customers with untapped insights and the
                  latest innovations to drive business value. Trust us to help you achieve your
                  goals with our unique blend of expertise and technology.
                </p>
              </div>
            </div>
          </div>
          <div className='-ml-12 -mt-16 p-12 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden'>
            <Card className='shadow-none pt-12 px-12 mx-auto'>
              <CardImage src={technologyChip} />
            </Card>
          </div>
        </div>
        <div className='mx-auto max-w-7xl px-6 lg:px-8 py-8'>
          <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            What Makes Us Different?
          </h1>
          <p className='mt-6 mb-6 text-xl leading-8 text-gray-700'>
            At LightFeather, we seamlessly merge advanced technical acumen with an unwavering
            dedication to our people, setting the gold standard in delivering tailored, data-driven
            solutions. Our comprehensive expertise spans from DevOps to AI, ensuring multifaceted
            client needs are met with precision and innovation. Central to our ethos is an agile,
            collaborative culture, emphasizing meticulous vetting, ensuring we consistently go
            beyond client expectations.
          </p>
          <div className='grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-3'>
            {differentiators.map((differentiator) => (
              <div key={differentiator.id}>
                <h3 className='text-blue-lighter mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600'>
                  <span className='absolute inset-0' />
                  {differentiator.title}
                </h3>
                <p className='mt-5 line-clamp-3 text-lg leading-8 text-gray-700'>
                  {differentiator.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Section>
  </Layout>
);

export default AboutUs;
